import React, { useContext, useEffect, useState, useRef } from 'react';
import { graphql } from "gatsby"
import Card  from '@material-ui/core/CardContent';
import CardActionArea  from '../components/CardActionArea';
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles';

// import useMatchedQuery from '../hooks/useMatchedQuery';

import GamesLayout from '../components/GamesLayout';
import SEO from '../components/SEO';
import SearchContext from '../components/TopBarGames/context';
import BackToTop from '../components/BackToTop';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  scroller: {
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
    boxSizing: 'content-box',
  },
  loading: {
    // bottom: 0,
    // position: 'absolute',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  }
}));

const Spacer = () => {
  const NAVIGATION_HEIGHT = 32;
  return (
    <div style={{ height: NAVIGATION_HEIGHT, content: '' }} />
  )
};

const AuthorCard = (props) => {
  const { author, city} = props
  // const url = `/${city}/autor/${author.contentful_id}/${author.slug}`
  const url = `/ba/autor/${author.contentful_id}/${author.slug}`
  return (
    <Paper>
      <Card>
        <CardActionArea to={url}>
          <Typography component="h4" variant="h4">{author.title}</Typography>
          <Typography component="h5" variant="h5">juegos: {author.games.length}</Typography>
        </CardActionArea>
      </Card>
    </Paper>
  )
}

const AuthorsList = (props) => {
  const { authors, scroller, city } = props
  return (
    <Grid container id="back-to-top-anchor" spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}/>
      {authors.map((author) => {
        return (
          <Grid key={author.contentful_id} item xs={12} sm={6} md={4} lg={3} xl={3}>
            <AuthorCard author={author} city={city} />
          </Grid>
        )
      })}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <BackToTop scroller={scroller} querySelector={["#back-to-top-anchor"]} />
      </Grid>
    </Grid>
  )
}

const Page = (props) => {
  const { data, pageContext } = props;
  const { authors, seo, site } = data
  const items = authors.edges.map((t) => t.node)
  const city = props.pageContext.city;
  const scroller = useRef();
  const classes = useStyles();
  const [db,] = useState(items);
  const description = seo.description || ''

  return (
    <>
      <SEO 
        {...seo}
        {...pageContext}
        description={description}
        pathName={`${city}/autores/`}
        seo={{...seo}}
        siteUrl={site.siteMetadata.siteUrl}
      />
      <GamesLayout {...pageContext} items={items} title={` por autores`} staticSearch={true}>
        <Spacer />
        <div ref={scroller} className={classes.scroller} style={{ height: props.height, width: props.width || '100%' }} >
          <SearchContext.Consumer>
            {search => {
              return (
              <React.Fragment>
                <AuthorsList authors={search.searchResult || db} scroller={scroller} city={city} />
              </React.Fragment>
            )
            }}
          </SearchContext.Consumer>
        </div>
      </GamesLayout>
    </>
  )
}

export default Page;

export const query = graphql`
  query($city: String="ba") {
      seo: contentfulComponentSeo(name: {eq: "games"}, node_locale: {eq: "es-AR"}) {
        title
        description {
          description
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED,
            width: 600,
            height: 600
          )
          id
          title
        }
        name
      }
      site: site {
        siteMetadata {
          siteUrl
        }
      }
      authors: allContentfulGameAuthor(
        sort: {fields: name, order: ASC}
        filter: {
          game: {
            elemMatch: {
              cities: {
                elemMatch: {contentful_id: {eq: $city}}
              }, 
              node_locale: {eq: "es-AR"}, 
              expansion: {eq: false},
            }
          }, node_locale: {eq: "es-AR"}}
      ) {
        total: totalCount
        edges {
          node {
            slug
            title: name
            id
            contentful_id
            games: game {
              id
              contentful_id
            }
          }
        }
      }
    
    }
`
